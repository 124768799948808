define("frontend/controllers/portal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Controller.extend({
    currentUser: inject.service(),
    router: inject.service(),
    currentRouteName: computed.readOnly('router.currentRouteName'),
    queryParams: ['signContract'],
    signContract: null,
    signContractParam: computed.alias('signContract'),
    onAppointmentRequestRoute: computed('currentRouteName', function () {
      return this.get('currentRouteName').split('.')[1] == 'appointment-request';
    }),
    actions: {
      reloadCurrentUser: function reloadCurrentUser(option) {
        var _this = this;

        this.get('currentUser').reload().then(function () {
          if (option == 'accepted') {
            _this.transitionToRoute('portal.billing', {
              queryParams: {
                addedClient: true
              }
            });
          } else {
            _this.send('refreshModel');
          }
        });
      },
      clearSignatureRequests: function clearSignatureRequests() {
        this.set('model.inhouseSignatures', []);
        this.set('signContract', null);
      }
    }
  });

  _exports.default = _default;
});