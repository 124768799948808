define("frontend/templates/portal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YGlUpL6m",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"currentUser\",\"user\",\"hasUnconfirmedCompany\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"accept-company-request\",null,[[\"household\",\"company\",\"onSave\"],[[24,[\"currentUser\",\"user\",\"household\"]],[24,[\"currentUser\",\"user\",\"unconfirmedCompany\"]],[28,\"action\",[[23,0,[]],\"reloadCurrentUser\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"gt\",[[24,[\"model\",\"inhouseSignatures\",\"length\"]],0],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"sign-contracts-modal\",null,[[\"signatureRequests\",\"signContractParam\",\"householdId\",\"clearSignatureRequests\"],[[24,[\"model\",\"inhouseSignatures\"]],[24,[\"signContractParam\"]],[24,[\"currentUser\",\"user\",\"household\",\"id\"]],[28,\"action\",[[23,0,[]],\"clearSignatureRequests\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"client-portal-container\"],[8],[0,\"\\n\"],[4,\"unless\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[4,\"unless\",[[24,[\"onAppointmentRequestRoute\"]]],null,{\"statements\":[[0,\"      \"],[1,[22,\"portal-sidebar-nav\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/portal.hbs"
    }
  });

  _exports.default = _default;
});